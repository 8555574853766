import { v4 as uuidv4 } from 'uuid'
import { ACCION_EJEC, TINCLUSION } from '@/utils/consts'

export default {
  async insert (Vue, formData, idparteTrabajo, idchecklistOt, idtecnico) {
    const tables = Vue.$offline.db.tables
    const materiales = await Vue.$offline.db
      .select()
      .from(tables.parte_trabajo)
      .innerJoin(
        tables.parte_trabajo_matsist,
        tables.parte_trabajo.idparte_trabajo.eq(tables.parte_trabajo_matsist.idparte_trabajo)
      )
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.parte_trabajo_matsist.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
      .innerJoin(
        tables.subsis,
        tables.material_sistema.idsubsis.eq(tables.subsis.idsubsis)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.parte_trabajo.idparte_trabajo.eq(idparteTrabajo),
          tables.subsis.idsubsis.in(formData.idsubsis),
          tables.parte_trabajo_matsist.estado.gt(0),
          tables.orden_trabajo_matsist.estado.gt(0),
          tables.material_sistema.estado.gt(0),
          tables.material_sistema.fdesinstalacion.isNull(),
          tables.subsis.estado.gt(0)
        )
      )
      .exec()
    for (const material of materiales) {
      for (const idmaccion of formData.idmaccion) {
        const annadirAccion = (await Vue.$offline.db
          .select()
          .from(tables.accion_ejec)
          .where(
            Vue.$offline.db.op.and(
              tables.accion_ejec.idmaccion.eq(idmaccion),
              tables.accion_ejec.idorden_trabajo_matsist.eq(material.orden_trabajo_matsist.idorden_trabajo_matsist),
              tables.accion_ejec.estado.gt(0),
            )
          )
          .exec()
        ).length === 0
        if (annadirAccion) {
          const idaccionEjec = uuidv4()
          await Vue.$offline.accionEjec.insertSync({
            idaccion_ejec: idaccionEjec,
            idorden_trabajo_matsist: material.orden_trabajo_matsist.idorden_trabajo_matsist,
            idmaccion: idmaccion,
            estado: 1,
            idest_accion_ejec: ACCION_EJEC.estados.pendiente,
            facturable: formData.facturable,
            idparte_trabajo: idparteTrabajo
          })
          if (formData.realizada) {
            await Vue.$offline.accionEjec.marcarAccionEjecRealizadaSync(
              idaccionEjec,
              idchecklistOt,
              formData.facturable,
              null,
              TINCLUSION.suministro,
              idtecnico,
              idparteTrabajo,
            )
          }
        }
      }
    }
  },
}
