<template>
  <b10-base>
    <b10-autocomplete
      v-if="isInsert"
      v-model="form.idsubsis"
      :items="subsis"
      item-value="subsis.idsubsis"
      item-text="subsis.descripcion"
      label="Subsistema"
      clearable
      :rules="formRules.idsubsis"
      multiple
      chips
      deletable-chips
    />
    <b10-autocomplete
      v-model="form.idmaccion"
      :items="formattedMacciones"
      item-value="maccion.idmaccion"
      item-text="title"
      label="Tipo de acción"
      clearable
      :rules="formRules.idmaccion"
      multiple
      chips
      deletable-chips
      @change="maccionSelected(form.idmaccion)"
    />
    <v-checkbox
      v-model="form.facturable"
      label="Es facturable"
    />
    <v-checkbox
      v-model="form.realizada"
      label="Marcar acción realizada"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './AccionEjecMasivaFormData'
import _ from '@/utils/lodash'

export default {
  mixins: [formMixin],
  props: {
    idparteTrabajo: {
      type: [Number, String],
      required: true,
    },
  },
  data () {
    return {
      form: {
        idsubsis: null,
        idmaccion: null,
        facturable: true,
        realizada: false,
      },
      formRules: {
        idsubsis: [
          v => !!v || 'Campo requerido'
        ],
        idmaccion: [
          v => !!v || 'Campo requerido'
        ],
      },
      macciones: [],
      subsis: [],
    }
  },
  computed: {
    formattedMacciones () {
      const items = []
      for (let item of this.macciones) {
        item.title = `${item.maccion.descripcion} (${item.maccion.descripcion_corta})`
        items.push(item)
      }
      return items
    },
  },
  async created () {
    this.macciones = await Data.selectMaccion(this)
    this.subsis = await Data.selectSubsis(this, this.idparteTrabajo)
    await this.loadForm()
  },
  methods: {
    async loadFormData () {
      // no tiene posibilidad de edicion
    },
    maccionSelected (idmaccion) {
      // la primera acción que selecciona determina el facturable incial luego
      // por defecto -> es facturable
      if (idmaccion.length === 1) {
        const maccionSeleccionada = _.filter(this.macciones, (item) => {
          return (item.maccion.idmaccion === idmaccion[0])
        })
        this.$set(this.form, 'facturable', maccionSeleccionada[0].maccion.facturable)
      } else {
        this.$set(this.form, 'facturable', true)
      }
    },
  }
}
</script>
