import { MACCION } from '@/utils/consts'

export default {
  selectMaccion (Vue) {
    const tables = Vue.$offline.db.tables
    const maccion = Vue.$offline.maccion
    return Vue.$offline.db
      .select()
      .from(tables.maccion)
      .innerJoin(maccion.dummyTable, maccion.pk.eq(maccion.dummyPk))
      .where(
        Vue.$offline.db.op.and(
          tables.maccion.estado.gt(0),
          // sin sustitución porque requiere de un comportamiento especial
          tables.maccion.clasificacion.neq(MACCION.clasificacion.sustitucion)
        )
      )
      .orderBy(tables.maccion.orden)
      .exec()
  },
  selectSubsis (Vue, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.parte_trabajo)
      .innerJoin(
        tables.orden_trabajo_subsis,
        tables.parte_trabajo.idorden_trabajo.eq(tables.orden_trabajo_subsis.idorden_trabajo)
      )
      .innerJoin(
        tables.subsis,
        tables.orden_trabajo_subsis.idsubsis.eq(tables.subsis.idsubsis)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.parte_trabajo.idparte_trabajo.eq(idparteTrabajo),
          tables.orden_trabajo_subsis.estado.gt(0),
          tables.subsis.estado.gt(0)
        )
      )
      .orderBy(tables.subsis.orden)
      .exec()
  },
}
